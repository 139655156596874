import React from "react";
import "./Say.css";
import Loading from "../System/Loading";
import SayUser from "./SayUser";

class Say extends React.Component {

    curr = this.props.site + "data/answer.json";

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            items: []
        };
    }

    componentDidMount() {
        fetch(this.curr)
            .then(response => response.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                }
            )
    }

    render() {
        const {items, isLoaded} = this.state;
        if (isLoaded) {
            return (
                <div>
                    <div className="wrap_say">
                        {
                            items.map((data) =>
                                <div className="wrap_answer_say" key={data.id}>
                                    <p className="answer_title_say">{data.title}</p>
                                    <p className="answer_text_say">{data.text}</p>
                                </div>
                            )
                        }
                    </div>
                    <SayUser/>
                </div>
            );
        } else {
            return (
                <Loading/>
            )
        }
    }
}

export default Say;
