import React from "react";
import "./PageHome.css";
import Menu from "../Menu/Menu";
import Home from "../Home/Home";
import About from "../About/About";
import Help from "../Help/Help";
import Price from "../Price/Price";
import Answer from "../Answer/Answer";
import Form from "../Form/Form";
import Footer from "../Footer/Footer";
import Popup from "../Popup/Popup";
import site from "../../sait1";

class PageHome extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <div>
        <title>ЦЕНТР ПСИХОЛОГИЧЕСКОЙ ПОДДЕРЖКИ</title>
        <Popup />
        <Menu history={history} site={site} />
        <Home />
        <About site={site} />
        <Help site={site} />
        <Price site={site} />
        <Answer site={site} />
        <Form site={site} />
        <Footer site={site} />
      </div>
    );
  }
}

export default PageHome;
