import React from "react";
import "./PageSay.css";
import success from "../FormUser/success.svg";

class PageSayForm extends React.Component {
  render() {
    return (
      <div className="wrap_form_say">
        <form className="add_say active">
          <div className="form-group">
            <label className="text-left d-block font-weight-bold text-white">
              Имя:
            </label>
            <input
              id="say_name"
              className="form-control"
              type="text"
              name="name"
              required
            />
            <label id="error_name" className="error_form"></label>
          </div>

          <div className="form-group">
            <label className="text-left d-block font-weight-bold text-white">
              Телефон:
            </label>
            <input
              id="say_phone"
              className="form-control"
              type="tel"
              name="phone"
              required
            />
            <label id="error_phone" className="error_form"></label>
          </div>

          <div className="form-group">
            <label className="text-left d-block font-weight-bold text-white">
              Отзыв:
            </label>
            <textarea
              id="say_text"
              className="form-control"
              rows="7"
              name="text"
              required
            />
            <label id="error_text" className="error_form"></label>
          </div>

          <div className="form-group">
            <button className="enter_say_button">ОТПРАВИТЬ</button>
          </div>
        </form>
        <div className="success_form_say">
          <p className="padding_tops text-white font-weight-bold">
            Ваш отзыв успешно отправлен <br />и будет опубликован после
            модерации.
          </p>
          <img className="white_success" src={success} alt="Success" />
          <p className="text-white font-weight-bold">
            Благодарим за обращение в Центр психологической поддержки
          </p>
        </div>
      </div>
    );
  }
}

export default PageSayForm;
