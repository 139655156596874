import React from "react";
import "./Footer.css";
import Loading from "../System/Loading";
import ok from './ok.svg';
import fb from './fb.svg';
import vk from './vk.svg';
import inst from './inst.svg';

class FooterContact extends React.Component {

    curr = this.props.site + "data/footer.json";

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            items: []
        };
    }

    componentDidMount() {
        fetch(this.curr)
            .then(response => response.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                }
            )
    }

    render() {
        const {items, isLoaded} = this.state;
        if (isLoaded) {
            return (
                <div className="wrap_contact">
                    <div className="container">
                        <div className="wrap_flex">
                            <div className="wrap_footer_left">
                                <p className="footer_title">БУДЕМ НА СВЯЗИ!</p>
                                <p className="footer_second m-0">ПОЗВОНИТЕ НАМ!</p>
                                <p className="footer_second mb-4">{items[0].phone}</p>
                                <p className="footer_second m-0">ПРИХОДИТЕ К НАМ!</p>
                                <p className="footer_second mb-4">{items[0].sit}</p>
                                <p className="footer_second m-0">По вопросам сотрудничества</p>
                                <p className="footer_second mb-4">{items[0].second}</p>
                                <p className="footer_second m-0">По вопросам аренды зала для тренингов</p>
                                <p className="footer_second mb-4">{items[0].arena}</p>
                                <div className="wrap_social">
                                    <a href='#'><img src={ok} alt="" className="social_footer social_hover"/></a>
                                    <a href='#'><img src={fb} alt="" className="social_footer social_hover"/></a>
                                    <a href='#'><img src={vk} alt="" className="social_footer social_hover"/></a>
                                    <a href='#'><img src={inst} alt="" className="social_footer social_hover"/></a>
                                </div>
                            </div>
                            <div className="footer_map">
                                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                                <iframe
                                    src="https://yandex.ru/map-widget/v1/?um=constructor%3Ade54ebd8e0f7f6e642192c7848f077758f78c60a06b47284d92038536dbacf07&amp;source=constructor"
                                    width="100%" height="100%" frameBorder="0"/>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <Loading/>
            )
        }
    }
}

export default FooterContact;
