import React from "react";
import "./Say.css";
import Loading from "../System/Loading";
import icon from "../Answer/icon.png";
import site from "../../sait1";

class SayUser extends React.Component {
  curr = site + "data/usersay.json";

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      items: [],
    };
  }

  componentDidMount() {
    fetch(this.curr)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          isLoaded: true,
          items: result,
        });
      });
  }

  render() {
    const { items, isLoaded } = this.state;
    if (isLoaded) {
      return (
        <div className="wrap_say">
          {items.map((data) => (
            <div
              className={data.publ == "1" ? "wrap_answer_say" : "d-none"}
              key={data.id}
            >
              <div className="image_answer_say">
                <img className="image_answer_img_say" src={icon} alt="" />
              </div>
              <p className="answer_title_say">{data.title}</p>
              <p className="answer_text_say">{data.text}</p>
            </div>
          ))}
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

export default SayUser;
