import React from "react";
import "./Form.css";
import logo from '../Help/logo.svg';
import arrow from './arrow.svg';
import FormUser from "../FormUser/FormUser";

class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    Form = () => {
        this.setState({
            isOpen: true
        })
    };

    Close = () => {
        this.setState({
            isOpen: false
        })
    };

    render() {
        const {isOpen} = this.state;
        return (
            <div>
                <div onClick={this.Close} className={isOpen ? "close_forms": "d-none"}/>
                <FormUser open={isOpen}/>
                <div className="wrap_form">
                    <div className="container">
                        <div className="title_form">ХОЧУ ЗАПИСАТЬСЯ НА КОНСУЛЬТАЦИЮ
                            <div className="wrap_logo_fr">
                                <img className="logo_fr" src={logo} alt=""/>
                            </div>
                        </div>
                        <p className="second_form">Кликните, чтобы записаться на консультацию</p>
                        <button className="btn_yellow position-relative mt-5" onClick={this.Form}>
                            <img className="arroe_in_btn" src={arrow} alt=""/>
                            Записаться
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
