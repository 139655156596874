import React from "react";

class HelpList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: true
        };
    }

    Close = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    };

    render() {
        const items = this.props.data;
        const about = this.state.isOpen && <p className="help_second">{items.helptext}</p>;
        const aboutth = this.state.isOpen && <p className="help_second help_left_un">{items.helptext}</p>;

        if (items.id === '3') {
            return (
                <div id={"help_item_" + items.id} key={items.id}>
                    <p className="help_text" onClick={this.Close}>{items.title}</p>
                    {aboutth}
                </div>
            )
        } else {
            return (
                <div id={"help_item_" + items.id} key={items.id}>
                    <p className="help_text" onClick={this.Close}>{items.title}</p>
                    {about}
                </div>
            )
        }

    }
}

export default HelpList;
