import React from "react";
import Loading from "../System/Loading";

import "./Answer.css";
import icon from './icon.png';

class Answer extends React.Component {

    curr = this.props.site + "data/answer.json";

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            items: []
        };
    }

    componentDidMount() {
        fetch(this.curr)
            .then(response => response.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                }
            )
    }

    render() {
        const {items, isLoaded} = this.state;
        if (isLoaded) {
            return (
                <div className="wrap_answer_parent">
                    <div className="container">
                        <div className="title_answer">
                            ТЫСЯЧИ РЕШЕННЫХ ПРОБЛЕМ ЗА ГОДЫ РАБОТЫ
                        </div>
                        <div className="second_answer">
                            Отзывы наших клиентов
                        </div>

                        <a href="/say">
                            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">

                                        <div className="wrap_answer" key={items['0'].id}>
                                            <div className="image_answer">
                                                <img className="image_answer_img" src={icon} alt=""/>
                                            </div>
                                            <p className="answer_title">{items['0'].title}</p>
                                            <p className="answer_text">{items['0'].text}</p>
                                        </div>

                                        <div className="wrap_answer dm" key={items['1'].id}>
                                            <div className="image_answer">
                                                <img className="image_answer_img" src={icon} alt=""/>
                                            </div>
                                            <p className="answer_title">{items['1'].title}</p>
                                            <p className="answer_text">{items['1'].text}</p>
                                        </div>

                                        <div className="wrap_answer dm" key={items['2'].id}>
                                            <div className="image_answer">
                                                <img className="image_answer_img" src={icon} alt=""/>
                                            </div>
                                            <p className="answer_title">{items['2'].title}</p>
                                            <p className="answer_text">{items['2'].text}</p>
                                        </div>

                                    </div>
                                    <div className="carousel-item">
                                        <div className="wrap_answer" key={items['3'].id}>
                                            <div className="image_answer">
                                                <img className="image_answer_img" src={icon} alt=""/>
                                            </div>
                                            <p className="answer_title">{items['3'].title}</p>
                                            <p className="answer_text">{items['3'].text}</p>
                                        </div>

                                        <div className="wrap_answer dm" key={items['4'].id}>
                                            <div className="image_answer">
                                                <img className="image_answer_img" src={icon} alt=""/>
                                            </div>
                                            <p className="answer_title">{items['4'].title}</p>
                                            <p className="answer_text">{items['4'].text}</p>
                                        </div>

                                        <div className="wrap_answer dm" key={items['5'].id}>
                                            <div className="image_answer">
                                                <img className="image_answer_img" src={icon} alt=""/>
                                            </div>
                                            <p className="answer_title">{items['5'].title}</p>
                                            <p className="answer_text">{items['5'].text}</p>
                                        </div>
                                    </div>
                                </div>
                                <a className="carousel-control-prev" href="#carouselExampleControls" role="button"
                                   data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#carouselExampleControls" role="button"
                                   data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"/>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </a>

                    </div>
                </div>
            );
        } else {
            return (
                <Loading/>
            )
        }
    }
}

export default Answer;
