import React from "react";
import success from "../FormUser/success.svg";

class FormService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IsOpen: true,
    };
  }

  Close = () => {
    this.setState({
      IsOpen: false,
    });
  };

  render() {
    const { id, email } = this.props;
    const { IsOpen } = this.state;

    return (
      <div className={IsOpen ? "wrap_user_form active" : "wrap_user_form"}>
        <div onClick={this.Close} className="close_forms" />
        <div className="back_form" />
        <form id="form_currentuser_check" className="in_form active">
          <div className="begin_form_user">ЗАПИСАТЬСЯ НА КОНСУЛЬТАЦИЮ</div>
          <div className="second_form_user">Прием ведет: {id}</div>
          <br />
          <div className="row nomobilerow">
            <div className="col">
              <input
                id="current_name"
                type="text"
                className="form-control"
                name="name"
                placeholder="Имя"
                required
              />
              <label id="error_name" className="error_form" />
            </div>
            <div className="col">
              <input
                id="current_phone"
                type="tel"
                className="form-control"
                name="phone"
                placeholder="Телефон"
                required
              />
              <label id="error_phone" className="error_form" />
            </div>
          </div>
          <div className="form-group d-none">
            <input
              type="email"
              className="form-control"
              name="from"
              value={email}
            />
            <input
              type="email"
              className="form-control"
              name="from_name"
              value={id}
            />
          </div>
          <div className="form-group">
            <input
              id="current_email"
              type="email"
              className="form-control"
              name="mail"
              placeholder="Электронная почта"
              required
            />
            <label id="error_mail" className="error_form" />
          </div>
          <div className="form-group">
            <label className="text-white">Укажите желаемую дату и время</label>
            <input
              id="current_date"
              type="datetime-local"
              className="form-control"
              name="date"
              required
            />
            <label id="error_date" className="error_form" />
          </div>
          <div className="form-group">
            <button
              id="formcurrentuser"
              className="btn_yellow_form form-control"
            >
              ЗАПИСАТЬСЯ
            </button>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="Check"
              required
              checked
            />
            <label className="form-check-label text-white" htmlFor="Check">
              Даю свое согласие на обработку персональных данных
            </label>
          </div>
        </form>
        <div className="success_form">
          <p className="padding_tops text-white font-weight-bold">
            Ваша заявка успешно отправлена!
          </p>
          <img className="white_success" src={success} alt="Success" />
          <p className="text-white font-weight-bold">
            С Вами свяжется наш специалист.
          </p>
          <p className="text-white font-weight-bold">
            Благодарим за обращение в Центр психологической поддержки.
          </p>
        </div>
      </div>
    );
  }
}

export default FormService;
