import React from "react";
import HelpList from "./HelpList";
import "./Help.css";
import logo from './logo.svg';
import Loading from "../System/Loading";

class Help extends React.Component {

    curr = this.props.site + 'data/help.json';

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            items: []

        };
    }

    componentDidMount() {
        fetch(this.curr)
            .then(response => response.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                }
            )
    }

    render() {
        const {isLoaded, items} = this.state;
        if (isLoaded) {
            return (
                <div className="wrap_out_help">
                    <div className="wrap_help">
                        <div className="container">
                            <p className="about_txt">ПРОФЕССИОНАЛЬНО<br/>ПОМОГАЕМ ИЗМЕНИТЬ ЖИЗНЬ<br/>К ЛУЧШЕМУ</p>
                            <div className="wrap_logo_ab">
                                <img className="logo_img_ab" src={logo} alt=""/>
                            </div>
                            <div className="wrap_centr"/>
                            {items.map((item) =>
                                <HelpList key={item.id} data={item}/>
                            )}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <Loading/>
            );
        }
    }
}

export default Help;
