import React from "react";
import logo from './logoanimate.svg';
import "./System.css";

class Loading extends React.Component {

    render() {
        return (
            <div className="loading d-flex">
                <img className="loading-img" src={logo} alt="logo"/>
            </div>
        )
    }
}


export default Loading;