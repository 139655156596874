import React from "react";
import "./People.css";
import phone from "./phone.svg";
import video from "./video.svg";
import wt from "./wts.png";
import vab from "./viber.png";

import FormCurrentUser from "../FormCurrentUser/FormCurrentUser";

class PeopleCard extends React.Component {
  constructor(props) {
    super(props);
    this.Form = this.Form.bind(this);
    this.Close = this.Close.bind(this);
    this.state = {
      isOpen: false,
      idUser: "",
      mailUser: "",
      showPhone: false,
      showWB: false,
      showVT: false,
    };
  }

  Form = (id, mail) => {
    this.setState({
      isOpen: true,
      idUser: id,
      mailUser: mail,
    });
  };

  Close = () => {
    this.setState({
      isOpen: false,
      isUser: "",
      mailUser: "",
    });
  };

  Show = () => {
    this.setState({
      showPhone: !this.state.showPhone,

      showWB: false,
      showVT: false,
    });
  };

  ShowWB = () => {
    this.setState({
      showWB: !this.state.showWB,

      showPhone: false,
      showVT: false,
    });
  };

  ShowVT = () => {
    this.setState({
      showVT: !this.state.showVT,

      showWB: false,
      showPhone: false,
    });
  };

  render() {
    const { showPhone, isOpen, showWB, showVT } = this.state;
    const data = this.props.data;
    if (isOpen) {
      return (
        <div>
          <div onClick={this.Close} className="close_forms" />
          <FormCurrentUser id={this.state.idUser} email={this.state.mailUser} />
        </div>
      );
    } else {
      return (
        <span className={data.status_word == 0 ? "d-none" : ""}>
          <div className="item_people" key={data.id}>
            <a className="nohover" href={"/doctor?" + data.id}>
              <img
                src={"../images/people/" + data.images_people_small}
                className={"peoples "}
              />
              <p className="people_title">
                {data.title}
                <br />
                {data.subtitle}
              </p>
              <p className="people_about">{data.about}</p>
            </a>
            <button
              className="people_orange root_hover"
              onClick={() =>
                this.Form(data.title + " " + data.subtitle, data.email)
              }
            >
              Запись на консультацию
            </button>
            <div className="wrap_people_bottom_images">
              {/* {data.id === "3" || data.id === "7" || data.id === "8" ? (
                ""
              ) : (
                <a
                  className="video_current_people"
                  href={
                    data.id === "6"
                      ? "/doctorvideo?7"
                      : "/doctorvideo?" + data.id
                  }
                >
                  <img className="people_bottom_images" src={video} alt="" />
                  Смотреть видео
                </a>
              )} */}
              <br />
              <a
                className={
                  showPhone
                    ? "text-dark text-decoration-none active"
                    : "text-dark text-decoration-none"
                }
                href={"tel:" + data.phone}
              >
                <img
                  onClick={this.Show}
                  className="people_bottom_images"
                  src={phone}
                  alt=""
                />
                {showPhone ? data.phone : ""}
              </a>
              <a
                className={
                  showWB
                    ? "text-dark text-decoration-none active"
                    : "text-dark text-decoration-none"
                }
                href={"tel:" + data.phone}
              >
                <img
                  onClick={this.ShowWB}
                  className="people_bottom_images"
                  src={wt}
                  alt=""
                />
                {showWB ? data.phone : ""}
              </a>
              <a
                className={
                  showVT
                    ? "text-dark text-decoration-none active"
                    : "text-dark text-decoration-none"
                }
                href={"tel:" + data.phone}
              >
                <img
                  onClick={this.ShowVT}
                  className="people_bottom_images"
                  src={vab}
                  alt=""
                />
                {showVT ? data.phone : ""}
              </a>
            </div>
          </div>
        </span>
      );
    }
  }
}

export default PeopleCard;
