import React from "react";
import "./Menu.css";
import Loading from "../System/Loading";
import {Link} from "react-router-dom"
import ok from "../Footer/ok.svg";
import fb from "../Footer/fb.svg";
import vk from "../Footer/vk.svg";
import inst from "../Footer/inst.svg";

class Menu extends React.Component {

    curr = this.props.site + 'data/menu.json';

    constructor(props) {
        super(props);
        this.Open = this.Open.bind(this);
        this.Close = this.Close.bind(this);
        this.state = {
            isLoaded: false,
            items: [],
            show: false
        };
    }

    componentDidMount() {
        fetch(this.curr)
            .then(response => response.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: false,
                    });
                }
            )
    }

    Open = () => {
        this.setState({
            show: true
        })
    };

    Close = () => {
        this.setState({
            show: false
        })
    };

    render() {
        const {items, isLoaded, show} = this.state;
        const {history} = this.props;

        const curr = history.location.pathname;

        if (show) {
            this.shown = 'sidebar_nav active';
            this.showm = 'back_menu active';
        } else {
            this.shown = 'sidebar_nav';
            this.showm = 'back_menu';
        }
        if (isLoaded) {
            return (
                <nav>
                    <div className="burger_nav" onClick={this.Open}>
                        <div className="wrap_buger_item">
                            <span className="burger_item"></span>
                            <span className="burger_item"></span>
                            <span className="burger_item"></span>
                        </div>
                    </div>
                    <div className={this.shown}>
                        <div className="burger_nav_cross" onClick={this.Close}>
                            <div className="wrap_buger_item">
                                <span className="burger_item_cross"></span>
                                <span className="burger_item_cros"></span>
                            </div>
                        </div>
                        {
                            items.map((data) =>
                                <Link key={data.id}
                                      className={data.href === curr ? "sidebar_item active" : "sidebar_item"}
                                      to={data.href}>{data.title}</Link>
                            )
                        }
                        <div className="wrap_social_menu">
                            <a href='#'><img src={ok} alt="" className="social_menu social_hover"/></a>
                            <a href='#'><img src={fb} alt="" className="social_menu social_hover"/></a>
                            <a href='#'><img src={vk} alt="" className="social_menu social_hover"/></a>
                            <a href='#'><img src={inst} alt="" className="social_menu social_hover"/></a>
                        </div>
                    </div>
                    <div className={this.showm}/>
                </nav>
            );
        } else {
            return (
                <Loading/>
            )
        }
    }
}

export default Menu;
