import React from "react";
import "./PageCurrent.css";
import CurrentPeople from "../CurrentPeople/CurrentPeople";
import Menu from "../Menu/Menu";
import site from "../../sait1";

class PageCurrent extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <div>
        <Menu history={history} site={site} />
        <CurrentPeople history={history} site={site} />
      </div>
    );
  }
}

export default PageCurrent;
