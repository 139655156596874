import React from "react";
import "./News.css";
import Loading from "../System/Loading";
import NewsList from "./NewsList";

class News extends React.Component {

    curr = this.props.site + "data/news.json";

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            items: [],
        };
    }

    componentDidMount() {

        fetch(this.curr)
            .then(response => response.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                }
            );
    }

    render() {
        const {items, isLoaded} = this.state;
        if (isLoaded) {
            return (
                <div className="container">
                    <p className="about_texts pb-5 pt-5">НОВОСТИ И АКЦИИ</p>
                    {
                        items.map((data) =>
                            <NewsList data={data} key={data.id}/>
                        )
                    }
                </div>
            );
        } else {
            return (
                <Loading/>
            )
        }
    }
}

export default News;
