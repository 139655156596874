import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import {createBrowserHistory} from 'history';
import './style.css';

import App from "./App";

const history = createBrowserHistory();

ReactDOM.render((
        <BrowserRouter history={history}>
            <App/>
        </BrowserRouter>
    ), document.getElementById('root')
);

