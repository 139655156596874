import React from "react";
import "./Popup.css";
import close from './close.svg';

class Popup extends React.Component {

    active;

    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isLoad: true,
            });
        }, 2500);
    };

    Close = () => {
        this.setState({
            isLoad: false
        })
    }

    render() {
        const {isLoad} = this.state;
        if (isLoad) {
            this.active = "active";
        } else {
            this.active = "";
        }

        return (
            <div className={"popup " + this.active}>
                <div className="popup_close" onClick={this.Close}>
                    <img className="img_close" src={close} alt=""/>
                </div>
                <p className="popup_text">
                    Мы используем куки, чтобы помочь вам в навигации и улучшении пользовательского опыта.
                </p>
                <button className="btn_yellow_popup" onClick={this.Close}>ХОРОШО</button>
            </div>
        )
    }
}

export default Popup;
