import React from "react";
import "./CurrentVideo.css";
import site from "../../sait1";
import Loading from "../System/Loading";
import Menu from "../Menu/Menu";

class CurrentVideo extends React.Component {
  curr = site + "data/video.json";

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      items: [],
    };
  }

  componentDidMount() {
    fetch(this.curr)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          isLoaded: true,
          items: result,
        });
      });
  }

  render() {
    const { items, isLoaded } = this.state;
    const { history } = this.props;

    const currr = history.location.search;
    const currs = currr.split("?");
    const num = currs["1"] - 1;
    const it = items[num];

    if (isLoaded) {
      return (
        <div>
          <title>{it.title}</title>
          <div className="back_video" />
          <Menu history={history} site={site} />
          <div className="container d-flex vh-100 position-relative">
            <a href="/people" className="close_video" />
            <div className="wrap_current_video">
              <p className="title_video">{it.title}</p>
              <iframe
                width="100%"
                height="100%"
                src={it.href_video}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

export default CurrentVideo;
