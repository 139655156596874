import React from "react";
import "./Video.css";
import Loading from "../System/Loading";

class Video extends React.Component {

    curr = this.props.site + "data/video.json";

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            items: []
        };
    }

    componentDidMount() {
        fetch(this.curr)
            .then(response => response.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                }
            )
    }

    render() {
        const {items, isLoaded} = this.state;

        console.log(items);
        if (isLoaded) {
            return (
                <div className="container">
                    <div className="back_video"/>
                    <p className="about_texts pb-5 pt-5">ВИДЕО</p>
                    {
                        items.map((data) =>
                            <span className={data.status_word == 0 ? "d-none" : ""}>
                                <div className={data.id === "3" ? "d-none" : "wrap_video"} key={data.id}>
                                    <div className="title_video curr_video">{data.title}</div>
                                    <div className="about_video">{data.about}</div>
                                    <iframe width="100%" height="270" src={data.href_video}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen/>
                                </div>
                            </span>
                        )
                    }
                </div>
            );
        } else {
            return (
                <Loading/>
            )
        }
    }
}

export default Video;
