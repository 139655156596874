import React from "react";
import map from "./map.svg";
import "./Home.css";
import FormUser from "../FormUser/FormUser";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  Form = () => {
    this.setState({
      isOpen: true,
    });
  };

  Close = () => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    const { isOpen } = this.state;
    if (isOpen) {
      return (
        <div>
          <div onClick={this.Close} className="close_forms" />
          <FormUser open={true} />
        </div>
      );
    } else {
      return (
        <div className="wrap_home">
          <div className="back_home" />
          <div className="wrap_home_menu">
            <div className="wrap_top_home">
              <p className="top_home_text">+7 (952) 954 93 78</p>
              <p className="top_home_second">
                <img className="map_logo" src={map} alt="" />
                Воронеж, Владимира Невского, 38е
              </p>
            </div>
            <p className="home_text">ЦЕНТР ПСИХОЛОГИЧЕСКОЙ ПОДДЕРЖКИ</p>
            <span className="need_help">
              ЧТО БЫ НИ СЛУЧИЛОСЬ, ЗАВТРА СНОВА ВЗОЙДЁТ СОЛНЦЕ
            </span>
            <div className="wrap_home_btn">
              <button className="home_btn" onClick={this.Form}>
                НУЖНА ПОМОЩЬ?
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Home;
