import React from "react";
import "./PageVideo.css";
import Video from "../Video/Video";
import Menu from "../Menu/Menu";
import site from "../../sait1";

class PageVideo extends React.Component {
  render() {
    const { history } = this.props;

    return (
      <div>
        <title>Видео</title>
        <Menu history={history} site={site} />
        <Video site={site} />
      </div>
    );
  }
}

export default PageVideo;
