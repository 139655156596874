import React from "react";
import "./PageSay.css";
import Say from "../Say/Say";
import Menu from "../Menu/Menu";
import site from "../../sait1";
import PageSayForm from "./PageSayForm";

class PageSay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  Show = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { history } = this.props;
    const { isOpen } = this.state;
    return (
      <div>
        <Menu history={history} site={site} />
        <title>Отзывы</title>
        <div className="wrap_say_page">
          <p className="about_texts pb-5 pt-5">ОТЗЫВЫ</p>
          <div className="wrap_says">
            <button onClick={this.Show} className="enter_say">
              {isOpen ? "ЗАКРЫТЬ" : "ОСТАВИТЬ ОТЗЫВ"}
            </button>
            {isOpen ? <PageSayForm /> : ""}
          </div>
          <div className="container">
            <Say site={site} />
          </div>
        </div>
      </div>
    );
  }
}

export default PageSay;
