import React from "react";
import "./PageContact.css";
import Menu from "../Menu/Menu";
import FooterContact from "../Footer/FooterContact";
import site from "../../sait1";

class PageContact extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <div>
        <title>ЦЕНТР ПСИХОЛОГИЧЕСКОЙ ПОДДЕРЖКИ</title>
        <Menu history={history} site={site} />
        <FooterContact site={site} />
      </div>
    );
  }
}

export default PageContact;
