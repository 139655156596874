import React from "react";
import "./PageAbout.css";
import Menu from "../Menu/Menu";
import People from "../People/People";
import site from "../../sait1";

class PageAbout extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <div>
        <title>Специалисты</title>
        <Menu history={history} site={site} />
        <People site={site} />
      </div>
    );
  }
}

export default PageAbout;
