import React from "react";
import "./News.css";
import Menu from "../Menu/Menu";
import Loading from "../System/Loading";

class NewsCurrent extends React.Component {

    curr = this.props.site + "data/news.json";

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            items: []
        };
    }

    componentDidMount() {
        fetch(this.curr)
            .then(response => response.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                }
            );
    }

    render() {
        const {history, site} = this.props;
        const {items, isLoaded} = this.state;

        const curr = history.location.search;
        const currs = curr.split('?');
        const num = currs['1'] - 1;
        const it = items[num];

        if (isLoaded) {
            return (
                <div className="wrap_current_news hh-100">
                    <Menu history={history} site={site}/>
                    <div className="container">
                        <p className="title_time pt-5">{it.time}</p>
                        <p className="green_titles m-0">{it.title}</p>
                        <p className="green_titles">{it.subtitle}</p>
                        <img src={"../images/news/" + it.images_news} className="img-fluid"/>
                        <p className="text-left m-0 pt-2">{it.about}</p><br/>
                        <p className="text-left m-0 wsba">{it.about_second}</p><br/>
                        <p className="text-left m-0 font-weight-bold brr">{it.btitle_first}</p>
                        <p className="text-left m-0 brr">{it.btext_first}</p><br/>
                        <p className="text-left m-0 font-weight-bold brr">{it.btitle_second}</p>
                        <p className="text-left m-0 wsba">{it.btext_second}</p><br/>
                        <p className="text-left m-0 font-weight-bold brr">{it.curtime}</p>
                        <p className="text-left m-0 font-weight-bold pb-5 brr">{it.price}</p>
                    </div>
                </div>
            );
        } else {
            return (
                <Loading/>
            )
        }
    }
}

export default NewsCurrent;
