import React from "react";
import "./People.css";
import Loading from "../System/Loading";
import PeopleCard from "./PeopleCard";

class People extends React.Component {

    curr = this.props.site + "data/people.json";

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            items: []
        };
    }

    componentDidMount() {
        fetch(this.curr)
            .then(response => response.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                }
            )
    }

    render() {
        const {items, isLoaded} = this.state;
        if (isLoaded) {
            return (
                <div className="container">
                    <p className="about_texts pb-5 pt-5">НАШИ СПЕЦИАЛИСТЫ</p>
                    <div onClick={this.Close} className="close_forms"/>
                    {items.map((item) =>
                        <PeopleCard key={item.id} data={item}/>
                    )}
                </div>
            )
        } else {
            return (
                <Loading/>
            )
        }
    }
}

export default People;
