import React from "react";
import site from "../../sait1";
import NewsCurrent from "../News/NewsCurrent";

class PageGroup extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <div>
        <title>ЦЕНТР ПСИХОЛОГИЧЕСКОЙ ПОДДЕРЖКИ</title>
        <NewsCurrent site={site} history={history} />
      </div>
    );
  }
}

export default PageGroup;
