import React from "react";
import "./PageNews.css";
import Menu from "../Menu/Menu";
import News from "../News/News";
import site from "../../sait1";

class PageNews extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <div>
        <title>Новости</title>
        <Menu history={history} site={site} />
        <News site={site} />
      </div>
    );
  }
}

export default PageNews;
