import React from "react";
import phoness from "./phone.svg";
import FormService from "../FormService/FormService";

class ServiceList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isOpenForm: false,
        };
    }


    Open = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    };

    Opens = () => {
        this.setState({
            isOpenForm: !this.state.isOpenForm
        });

    };

    render() {
        const {isOpen, isOpenForm} = this.state;
        const {data} = this.props;

        return (
            <span className={data.status_word == 0 ? "d-none" : ""}>
            <div className="wrap_service">
                {isOpenForm && <FormService id={data.author} email={data.email}/>}
                <div className="wrap_img_service">
                    <img className="in_service_img" src={"../images/service/" + data.images_service}
                         alt={data.images}/>
                </div>
                <div className="wrap_contant_service">
                    <p className="title_service">{data.title}</p>
                    <p className="people_service">Кто оказывает услугу: {data.author}</p>
                    <p onClick={this.Open} className={isOpen ? "about_service active" : "about_service"}>
                        {data.about1}
                        <br/>
                        <br/>
                        {data.about2}
                        <br/>
                        <br/>
                        <span
                            className={isOpen ? "more_service nohovers active" : "more_service nohovers"}>{isOpen ? "Свернуть" : "...Читать далее"}</span>
                    </p>
                </div>
                <div className="wrap_green">
                    <p className="form_service">Форма работы:<br/>{data.what}</p>
                    <p className="form_service">Продолжительность:<br/>{data.time}</p>
                    <p className="form_service">Стоимость:<br/>{data.price}</p>
                    <p className="form_btn_service nohover root_hover" onClick={this.Opens}>Записаться</p>
                    <a className="call_service nohover" href={'tel:' + data.u_id}><img className="phone_service"
                                                                                       src={phoness}
                                                                                       alt=""/>Позвонить</a>
                </div>
            </div>
            </span>
        )
    }

}

export default ServiceList;
