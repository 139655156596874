import React from "react";
import Loading from "../System/Loading";
import ServiceList from "./ServiceList";

class Service extends React.Component {

    curr = this.props.site + "data/service.json";

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isOpen: false,
            items: []
        };
    }

    componentDidMount() {
        fetch(this.curr)
            .then(response => response.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                }
            );
    }

    Open = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    };

    render() {
        const {items, isLoaded} = this.state;
        if (isLoaded) {
            return (
                <div>
                    <p>
                        При индивидуальном консультировании (или работе с парой) психолог выбирает методику, которая
                        подходит именно вам. Это может быть и психоанализ, и песочная, гештальт- или сказкотерапия, и
                        метафорические ассоциативные карты (МАК), и многое другое.
                        <br/>
                        <br/>
                        Психологическая группа также может стать для вас инструментом познания себя и эффективной работы
                        над собой, исправления жизненных ситуаций.
                        <br/>
                        <br/>
                    </p>
                    {
                        items.map((data) =>
                            <ServiceList key={data.id} data={data} />
                        )
                    }
                </div>
            );
        } else {
            return (
                <Loading/>
            )
        }
    }
}

export default Service;
