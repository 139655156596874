import React from "react";
import "./Price.css";
import Loading from "../System/Loading";

class Price extends React.Component {

    curr = this.props.site + 'data/price.json';
    order;

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            items: []
        };
    }

    componentDidMount() {
        fetch(this.curr)
            .then(response => response.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                }
            )
    }

    render() {
        const {items, isLoaded} = this.state;

        if (isLoaded) {
            return (
                <div className="wrap_price">
                    <div className="container">
                        {
                            items.map((data) =>
                                <div key={data.id} className="card_price">
                                    <div className="quard_price"/>
                                    {data.id > 2 ? this.order = true : this.order = false}
                                    <div className={this.order ? "in_price_order" : "in_price"}>
                                        <p className="title_price">{data.title}</p>
                                        <p className="sum_price">{data.price}</p>
                                    </div>
                                    <div id={"out_price_" + data.id} className="out_price"/>
                                </div>
                            )
                        }
                        <button className="btn_yellow mt-5"><a className="text-white text-decoration-none"
                                                               href="/service">Все услуги</a></button>
                    </div>
                </div>
            );
        } else {
            return (
                <Loading/>
            )
        }
    }
}

export default Price;
