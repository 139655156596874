import React from "react";
import "./PageService.css";
import Menu from "../Menu/Menu";
import Service from "../Service/Service";
import site from "../../sait1";

class PageService extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <div>
        <title>Услуги</title>
        <Menu history={history} site={site} />
        <div className="container">
          <p className="about_texts pb-5 pt-5">УСЛУГИ</p>
          <Service history={history} site={site} />
        </div>
      </div>
    );
  }
}

export default PageService;
