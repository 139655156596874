import React from "react";
import "./News.css";

class NewsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isHover: false,
            isOpen: false,
        };
    }

    isHover = () => {
        this.setState({
            isHover: true
        })
    };

    isOut = () => {
        this.setState({
            isHover: false
        })
    };


    render() {
        const data = this.props.data;
        const hover = this.state.isHover ? " active" : '';
        const {isOpen} = this.state;
        return (
            <span className={data.status_word == 0 ? "d-none" : ""}>
            <a className="item_news nohovers text-dark" href={"/group?" + data.id} onMouseEnter={this.isHover}>
                <img src={"../images/news/" + data.images_news} onMouseEnter={this.isHover} onMouseOut={this.isOut}
                     className={"images_news" + hover}/>
                <p onMouseEnter={this.isHover} onMouseOut={this.isOut} className="title_news">{data.title}</p>
                <div onMouseEnter={this.isHover} onMouseOut={this.isOut}
                     className={isOpen ? "second_news active" : "second_news"}>{data.about}<span
                    className="gray_news_about">...Читать далее</span>
                </div>
                <div onMouseEnter={this.isHover} onMouseOut={this.isOut}
                     className="btn_news_yellow root_hover">{data.time}</div>
            </a>
            </span>
        );
    }
}

export default NewsList;
