import React from "react";
import "./CurrentPeople.css";
import Loading from "../System/Loading";
import phone from '../People/phone.svg'
import data from './stage';
import FormCurrentUser from "../FormCurrentUser/FormCurrentUser";

class CurrentPeople extends React.Component {

    curr = this.props.site + "data/currentpeople.json";
    currs = this.props.site + "data/people.json";

    stages;
    second_year;

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isOpen: false,
            idUser: '',
            mailUser: '',
            items: [],
            itemss: [],
        };
    }

    componentDidMount() {

        fetch(this.curr)
            .then(response => response.json())
            .then(
                (result) => {
                    this.setState({
                        items: result,
                    });
                }
            );

        fetch(this.currs)
            .then(response => response.json())
            .then(
                (results) => {
                    this.setState({
                        itemss: results,
                        isLoaded: true,
                    });
                }
            );


    }

    Form = (id, mail) => {
        this.setState({
            isOpen: true,
            idUser: id,
            mailUser: mail
        })
    };

    Close = () => {
        this.setState({
            isOpen: false,
            isUser: '',
            mailUser: ''
        })
    };

    render() {
        const {items, itemss, isLoaded, isOpen} = this.state;
        const {history} = this.props;

        const curr = history.location.search;
        const currs = curr.split('?');
        const num = currs['1'] - 1;
        const it = items[num];
        const itt = itemss[num];

        if (isOpen) {
            return (
                <div>
                    <div onClick={this.Close} className="close_forms"/>
                    <FormCurrentUser id={this.state.idUser} email={this.state.mailUser}/>
                </div>
            )
        } else {
            if (isLoaded) {
                this.stages = new Date().getFullYear() - it.stage;
                return (
                    <div className="container">
                        <title>{it.title + ' - ' + it.titles}</title>
                        <div className="wrap_current_people_card">
                            <div className="wrap_current_people" key={it.id}>
                                <div className="title_current_people">{it.title}</div>
                                <div className="title_current_people_min">{it.titles}</div>
                                <div className="stage_current">Стаж: {this.stages + ' ' + data[this.stages]}
                                    <div className="bottom_gray"/>
                                </div>
                                <div className="pt-4"><b>Специализация:</b> {it.spec}</div>
                                <div className="pt-4 zapros_current_people"><b>Основные запросы, с которыми я
                                    работаю:</b><br/>{it.zapros}</div>
                                <div className="pt-4 zapros_current_people"><b>Образование:</b> <br/>{it.school}</div>
                                <div className="pt-4 zapros_current_people"><b>{it.last}</b></div>
                            </div>
                            <div className="card_current_people">
                                <img src={"../images/people/" + itt.images_people_small} className={"currppl"}/>
                                <button className="people_orange root_hover mt-5"
                                        onClick={() => this.Form(it.title, it.email)}>Запись на
                                    консультацию
                                </button>
                                <div className="bottom_phone_current">
                                    <a className="text-dark text-decoration-none" href={"tel: " + it.phone}><img
                                        className="people_bottom_images crss" src={phone} alt=""/>{it.phone}</a>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                    </div>
                );
            } else {
                return (
                    <Loading/>
                )
            }
        }
    }
}

export default CurrentPeople;
