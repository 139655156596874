import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Loading from "./Components/System/Loading";
import PageHome from "./Components/PageHome/PageHome";
import PageAbout from "./Components/PageAbout/PageAbout";
import PageNews from "./Components/PageNews/PageNews";
import PageService from "./Components/PageService/PageService";
import PageCurrent from "./Components/PageCurrent/PageCurrent";
import PageSay from "./Components/PageSay/PageSay";
import PageVideo from "./Components/PageVideo/PageVideo";
import CurrentVideo from "./Components/CurrentVideo/CurrentVideo";
import PageContact from "./Components/PageContact/PageContact";
import PageGroup from "./Components/PageGroup/PageGroup";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoaded: true,
      });
    }, 250);
  }

  render() {
    const { history } = this.props;

    if (!this.state.isLoaded) {
      return (
        <div>
          <Loading />
        </div>
      );
    } else {
      return (
        <div>
          <Switch>
            <Route exact history={history} path="/" component={PageHome} />
            <Route
              exact
              history={history}
              path="/people"
              component={PageAbout}
            />
            <Route exact history={history} path="/news" component={PageNews} />
            <Route
              exact
              history={history}
              path="/service"
              component={PageService}
            />
            <Route
              exact
              history={history}
              path="/doctor"
              component={PageCurrent}
            />
            <Route
              exact
              history={history}
              path="/doctorvideo"
              component={CurrentVideo}
            />
            <Route exact history={history} path="/say" component={PageSay} />
            <Route
              exact
              history={history}
              path="/video"
              component={PageVideo}
            />
            <Route
              exact
              history={history}
              path="/contact"
              component={PageContact}
            />
            <Route
              exact
              history={history}
              path="/group"
              component={PageGroup}
            />
          </Switch>
        </div>
      );
    }
  }
}

export default withRouter(App);
