import React from "react";
import "./About.css";
import people from "./people.jpg";

class About extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="wrap_about">
          <div className="wrap_text_about">
            <p className="about_text pb-3">ЗАЧЕМ ХОДИТЬ К ПСИХОЛОГУ?</p>
            <p className="about_second_text pb-3">
              Не нужно, если проснулись – и чувствуете радость. Радость от
              жизни, работы, отношений с близкими и другими людьми. Если ничего
              не болит и не беспокоит, нет никаких гнетущих мыслей, чувства
              растерянности.
              <br />
              <br />А если на душе тревога, постоянная неудовлетворенность, и вы
              не знаете, как справиться с жизненными трудностями? Работа с
              грамотным специалистом откроет вам возможность реальных изменений,
              тому, что можно назвать трансформацией личности.
            </p>
            <button className="btn_yellow">
              <a className="text-white nohover" href="/people">
                Выбрать психолога
              </a>
            </button>
          </div>
          <div className="wrap_img_about">
            <img className="people" src={people} alt="foto" />
            <p className="about_people_text">
              Вячеслав Васильевич ПРИБЫТКОВ,
              <br />
              психолог, руководитель центра
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
